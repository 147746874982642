import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../../components/layout";
import { HTMLContent } from "../../../components/content";

import { getSectionColor } from "../../../utils/color";

import '../../../styles/video.css';

import Img, { GatsbyImageFluidProps } from "gatsby-image";

import ReactPlayer from "react-player";
import ArticleHeader from "../../../components/articleHeader";
import { useMediaQuery } from "@react-hook/media-query";

type VideoTemplateProps = {
  html: string
  slug: string
  description: string
  author: string
  videoID: string
  title: string
  featuredImage: {
    childImageSharp: GatsbyImageFluidProps
  }
  helmet: any
}

export const VideoTemplate = ({
  description,
  html,
  slug,
  featuredImage,
  author,
  videoID,
  title,
  helmet,
}: VideoTemplateProps) => {
  // TODO: Change this
  const colors = getSectionColor('one');
  const { primary, secondary } = colors;
  const isMobile = useMediaQuery('only screen and (max-width: 768px)') 

  const mainRef = useRef<HTMLElement>(null);
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const [muted, setMuted] = useState(false);

  useEffect(() => {
    if (mainRef && mainRef.current) {
      mainRef!.current!.style.setProperty("--secondary-color", secondary);
      mainRef!.current!.style.setProperty("--primary-color", primary);
    }
  }, [mainRef]);

  console.log(title, description, author, featuredImage)
  if (isMobile) {
  return (
    <Layout postTitle={title}
    postDescription={description}
    isHome={false}
    slug={slug}
    >
    <div className="text-gray-600 h-screen" style={{backgroundColor: "#e3acb9"}}>
      <div className="max-w-4xl mx-auto mt-12 flex justify-center items-center h-full md:h-screen">
        <div className="px-4 md:px-0 flex flex-col md:flex-row justify-between items-center">
          <div className="px-0 md:px-8 max-w-4xl flex-grow-1">  
        <div className="flex-shrink-1 p-8 mb-8 flex max-w-4xl flex-col bg-gray-50 items-center rounded-md">
          <h1 className="text-gray-800 font-bold text-xl md:text-4xl">
            {title}
          </h1>
          <p className="italic pt-6 text-gray-500 text-sm md:text-base">
            {description}
          </p>
          <p className="w-full font-semibold text-gray-700 pt-6 text-left text-sm md:text-base">
            {author}
          </p>
        </div>
          {
            isMobile ? 
            <div className="player-wrapper">
            <ReactPlayer controls={true} width="100%" height="100%" url="https://www.youtube.com/watch?v=MzQlbK_8ibU"/>
            </div>: 
            <ReactPlayer controls={true}  url="https://www.youtube.com/watch?v=MzQlbK_8ibU"/>
          }
          </div>

        </div>
      </div>
    </div>
    </Layout>
  );
  }
  return (
    <Layout 
      postTitle={title}
      postDescription={description}
      isHome={false}
      slug={slug}
    >
    <div className="text-gray-600 h-screen" style={{backgroundColor: "#e3acb9"}}>
      <div className="max-w-4xl mx-auto mt-12 flex justify-center items-center h-full md:h-screen">
        <div className="px-4 md:px-0 flex flex-col md:flex-row justify-between items-center">
          <div className="px-0 md:px-8 max-w-4xl flex-grow-1">  
          {
            isMobile ? 
            <div className="player-wrapper">
            <ReactPlayer controls={true}  width="100%" height="100%" url="https://www.youtube.com/watch?v=MzQlbK_8ibU"/>
            </div>: 
            <ReactPlayer  controls={true} url="https://www.youtube.com/watch?v=MzQlbK_8ibU"/>
          }
          </div>

        <div className="flex-shrink-1 p-8 pb-0 flex max-w-4xl flex-col bg-gray-50 items-center rounded-md">
          <h1 className="text-gray-800 font-bold text-xl md:text-4xl">
            {title}
          </h1>
          <p className="italic pt-6 text-gray-500 text-sm md:text-base">
            {description}
          </p>
          <p className="w-full font-semibold text-gray-700 pt-6 text-left text-sm md:text-base">
            {author}
          </p>
        </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

type VideoPostProps = {
    data: { markdownRemark: {
      html: string,
        id: string,
        frontmatter: {
            title: string
            description: string
            author: string
            videoID: string
            slug: string
            featuredImage: {
              childImageSharp: GatsbyImageFluidProps
            }
        }
    } }
}


const VideoPost = ({ data }: VideoPostProps) => {
  const { markdownRemark: post } = data;
  return (
      <VideoTemplate
        slug={post.frontmatter.slug}
        html={data.markdownRemark.html}
        description={post.frontmatter.description}
        featuredImage={post.frontmatter.featuredImage}
        helmet={
          <Helmet titleTemplate='%s | Blog'>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name='description'
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        author={post.frontmatter.author}
        videoID={post.frontmatter.videoID}
        title={post.frontmatter.title}
      />
  );
};

export const pageQuery = graphql`
  query VideoPostByID {
    markdownRemark(frontmatter: {slug: {eq: "nostalgic-for-the-present"}}) {
      id
      html
      frontmatter {
        title
        description
        author
        videoID
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default VideoPost